import { useRouter, useSearchParams } from 'next/navigation'
import { useDebounce } from '@uidotdev/usehooks'
import {
  useSearchOnTypeLandingQuery,
  useSearchLandingQuery,
} from '@/src/queries/landing/search'
import { useState, useEffect, useCallback } from 'react'
import { Icons } from '../icons'
import { RxCross1 } from 'react-icons/rx'
import { Icon, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'

export const SearchMobile = () => {
  const router = useRouter()
  const [searchVal, setSearchVal] = useState('')
  const sp = useSearchParams()
  const debouncedQuery = useDebounce(searchVal, 200)

  const { data: searchText } = useSearchOnTypeLandingQuery(debouncedQuery)

  const { data: searchRecommendations } = useSearchLandingQuery()

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(sp?.toString())
      params.set(name, value)
      return params.toString()
    },
    [sp]
  )

  const handleSearchSubmit = () => {
    if (searchVal.length == 0) {
      return
    } else {
      // insertSearchTextMutation.mutate(searchVal)
      setIsMobileSearchVisible(false)
      router.push('/search/?' + createQueryString('q', searchVal))
    }
  }

  const handleSuggestionSubmit = (suggestion: string) => {
    // insertSearchTextMutation.mutate(suggestion)
    setSearchVal(suggestion)
    setIsMobileSearchVisible(false)
    router.push('/search/?' + createQueryString('q', suggestion))
  }

  const [searchData, setSearchData] = useState<string[]>([])
  const [trendingData, setTrendingData] = useState<string[]>([])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value)
  }

  useEffect(() => {
    if (searchVal) {
      setSearchData(searchText?.map((st) => st.description) || [])
      setTrendingData([])
    } else {
      setSearchData(
        searchRecommendations
          ?.filter((fil) => fil.sreachType === 'history')
          ?.map((st) => st.description) || []
      )
      setTrendingData(
        searchRecommendations
          ?.filter((fil) => fil.sreachType === 'popular')
          ?.map((st) => st.description) || []
      )
    }
  }, [searchVal, searchText, searchRecommendations])

  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false)

  const handleMoveLeft = () => {
    setIsMobileSearchVisible(false)
  }

  return (
    <div className="relative">
      <div className="">
        <Icons.Search
          className="z-20 cursor-pointer text-white xs:w-7 sm:block md:hidden"
          onClick={() => setIsMobileSearchVisible(true)}
        />
      </div>
      <div className="sm:block md:hidden">
        {/* {isMobileSearchVisible && ( */}
        <Modal
          isOpen={isMobileSearchVisible}
          onClose={() => setIsMobileSearchVisible(false)}
          size={'full'}
        >
          <ModalOverlay />
          <ModalContent>
            <div className="fixed left-0 top-0 z-20 h-screen w-screen overflow-y-auto bg-white">
              <span className="mx-auto flex w-[95%] items-center justify-center">
                <button
                  type="submit"
                  className="z-20 mt-0 h-[50px] w-[50px] border-0 bg-white text-[#94989b]"
                >
                  <Icons.moveLeft
                    className="w-[44px]"
                    onClick={handleMoveLeft}
                  />
                </button>
                <div className="mx-auto my-[20px] flex w-[80%] items-center overflow-hidden rounded-[30px] border-[1px] border-[#dfdfdf] px-[20px] shadow-md">
                  <input
                    className="shadow-0 relative z-10 mb-0 h-[49px] w-full focus-visible:shadow-none focus-visible:outline-none focus-visible:ring-0"
                    placeholder="Search..."
                    type="text"
                    name="search"
                    defaultValue={sp?.get('q') || ''}
                    value={searchVal}
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (event.key == 'Enter') {
                        handleSearchSubmit()
                      }
                    }}
                    inputMode="search"
                    enterKeyHint="search"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                    aria-haspopup="false"
                    aria-autocomplete="list"
                  />
                  <input type="submit" hidden />
                  {searchVal.length > 0 ? (
                    <Icon
                      as={RxCross1}
                      color="lyk-red"
                      h="auto"
                      w="20px"
                      onClick={() => setSearchVal('')}
                    />
                  ) : (
                    <Icons.Search className="h-auto w-[20px] text-black/80" />
                  )}
                </div>
                {/* <Icons.Microphone className="mr-4 w-[44px] cursor-pointer rounded-full bg-white p-2 opacity-75" /> */}
              </span>

              <div className="mx-auto my-[20px] w-[95%] text-[16px]">
                <ul className="">
                  {searchData.map((item, index) => (
                    <li
                      key={index}
                      onMouseDown={() => {
                        handleSuggestionSubmit(item)
                      }}
                      className="px-auto flex gap-3 py-[6px]"
                    >
                      <div className="pr-2">
                        <Icons.Search className="h-auto w-[20px] text-black/80" />
                      </div>
                      <SearchCompletion
                        text={item.toLowerCase()}
                        query={searchVal.trim().toLowerCase()}
                      />
                    </li>
                  ))}
                  {trendingData.length > 0 &&
                    trendingData.map((item, index) => (
                      <li
                        key={index}
                        onMouseDown={() => {
                          handleSuggestionSubmit(item)
                        }}
                        className="px-auto flex items-center gap-3 py-[6px]"
                      >
                        <div className="pr-2">
                          <Icons.trendingUp className="h-auto w-[20px] text-green" />
                        </div>
                        <Text>
                          <span className="font-semibold text-green">
                            {item.toLowerCase()}
                          </span>
                        </Text>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </ModalContent>
        </Modal>
        {/* )} */}
      </div>
    </div>
  )
}

export const SearchCompletion = ({
  text,
  query,
}: {
  text: string
  query: string
}) => {
  const index = text.indexOf(query)
  const before = index < 0 ? text : text.slice(0, index)
  const after = text.slice(index + query.length)

  return (
    <Text>
      <span className="font-semibold text-black">{before}</span>
      <span className="font-medium text-[#7A7A7A]">
        {index >= 0 ? query : ''}
      </span>
      <span className="font-semibold text-black">
        {index >= 0 ? after : ''}
      </span>
    </Text>
  )
}
