import { makeApiRequest } from '@/src/lib/api-request'
import { useQuery } from '@tanstack/react-query'
import { useLocationQuery } from '../guest/location-query'
import { useUser } from '@/src/store/user-store'

interface SearchOnTypeDto {
  description: string
  country: string
  city: string
  ip: string
}

export const useSearchLandingQuery = () => {
  const { user, _hasHydrated } = useUser()
  const { data } = useLocationQuery()
  return useQuery({
    queryKey: ['search', data?.city, data?.country],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'POST',
        url: user
          ? '/Landing/get_Search_Recomandation_withLogin'
          : '/Landing/get_Search_Recomandation_withoutLogin',
        isAuthenticated: user ? true : false,
        sendCredentials: true,
        data: {
          city: data?.city || '',
          country: data?.country || '',
        },
        fallbackError: 'Error while fetching search results',
      })
      const res = response.response.data as [{ description: string, sreachType: string }]
      return res.filter((item) => item.description.trim() !== '')
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  })
}

export const useSearchOnTypeLandingQuery = (debouncedQuery: string) => {
  const { user, _hasHydrated } = useUser()
  const { data: locationData } = useLocationQuery()
  return useQuery({
    queryKey: [
      'search-type',
      debouncedQuery,
      locationData?.city,
      locationData?.country,
      locationData?.ip,
    ],
    queryFn: async ({ queryKey }) => {
      const query: string = typeof queryKey[1] === 'string' ? queryKey[1] : ''
      const dataProps: SearchOnTypeDto = {
        description: query,
        city: locationData?.city ?? '',
        country: locationData?.country ?? '',
        ip: locationData?.ip ?? '',
      }
      const response = await makeApiRequest({
        method: 'POST',
        url: user
          ? 'Landing/getSearchText_withLogin'
          : 'Landing/getSearchText_withoutLogin',
        isAuthenticated: user ? true : false,
        sendCredentials: true,
        data: dataProps,
        fallbackError: 'Error while fetching search results',
      })

      const res = response.response.data as [{ description: string }]
      return res.filter((item) => item.description.trim() !== '')
    },
    refetchOnWindowFocus: false,
    enabled: !!debouncedQuery,
  })
}
