import {Text, Flex}  from '@chakra-ui/react';


export const OnboardingModalHeader = ({
    subtitle , subdescription
} : {subtitle : string |undefined
subdescription : string |undefined})=>{

    return (<>
      <Flex direction={'column'} alignItems={{base:'flex-start', lg:'center'}}>
        <Text
          as={'h2'} 
          mt={{
            base: 4,
            sm: 5,
          }}
          fontWeight="medium"
          color={'lyk-black'}
          fontSize={{ base: 'lg', lg:'xl'}}
          className='font-bold'
        >
          {subtitle}
        </Text>
        {subdescription && <Text
          as={'p'}
          my={{
            base: 1,
            sm: 1,
          }}
          fontSize={{
            base: 'sm',
            md: 'sm',
          }}
          color='gray.500'
          textAlign={{base:'left', 'lg':'center'}}
        >
          {subdescription}
        </Text>}
        </Flex>
      </>);
}