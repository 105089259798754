'use client'

import * as React from 'react'

import { cn } from '@/lib/utils'
import { Icons } from '../icons'
import { useState } from 'react'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, defaultValue, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>(
      (defaultValue || '') as string
    )

    const handleTogglePasswordVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible)
    }

    const getInputType = () => {
      if (isPasswordVisible) {
        return 'text'
      } else if (type === 'password') {
        return 'password'
      } else {
        return type
      }
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
    }

    return (
      <div className="relative flex flex-1">
        <input
          type={type === 'password' ? getInputType() : type}
          autoComplete={type === 'password' ?'off':'on'}
          className={cn(
            'border-input bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          value={inputValue}
          onChange={handleInputChange}
          {...props}
        />
        {type === 'search' && (
          <Icons.Search className="absolute right-5 top-3 mr-1 h-4 w-4 shrink-0 opacity-50" />
        )}
        {type === 'password' && inputValue && (
          <button
            type="button"
            className="absolute inset-y-0 right-3 top-2 px-2"
            onClick={handleTogglePasswordVisibility}
          >
            {isPasswordVisible ? <Icons.eyeOff /> : <Icons.eye />}
          </button>
        )}
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
