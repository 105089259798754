import {
  Input,
  InputGroup,
  InputRightElement,
  Wrap,
  WrapItem,
  Flex,
  Button,
} from '@chakra-ui/react'
// import { Button } from '../ui/button'
// import { communicationLanguages } from '@/data/auth/onboarding'
import { Icons } from '../icons'
import { useEffect, useState } from 'react'
import { cn } from '@/lib/utils'
import { useLanguagesQuery } from '@/src/queries/auth/languages-query'

export const Languages = ({
  selectedLanguages,
  setSelectedLanguages,
  position,
}: {
  selectedLanguages: string[]
  setSelectedLanguages: (selectedLanguages: string[]) => void
  position: string
}) => {
  const [searchText, setSearchText] = useState('')
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }
  const languages = useLanguagesQuery()

  useEffect(() => {
    if(selectedLanguages.length>0&&languages&&languages.data&&languages.data.length>0){
     const parentArray = languages.data?.map((language) =>language.id)
     const childArray = [...selectedLanguages];
     const filteredChildArray = childArray.filter(element => parentArray.includes(element));
     if(filteredChildArray.length!==childArray.length){
       setSelectedLanguages(filteredChildArray); 
     }
    }
   }, [])
  return (
    <>
      <Flex alignItems={position} flexDirection="column">
        <InputGroup maxW="md" mb={4}>
          <Input
            placeholder="Search"
            rounded={'full'}
            onChange={handleInputChange}
            value={searchText}
            fontSize={'sm'}
            _active={{ borderColor: '#D9D9D9' }}
            _focus={{ borderColor: '#D9D9D9', boxShadow: 'none' }}
          />
          <InputRightElement>
            <Icons.Search className="mr-2 h-5 w-5 text-black opacity-50" />
          </InputRightElement>
        </InputGroup>
        <Wrap
          spacing={{
            base: 3,
            sm: 3,
          }}
          className="mb-4"
          justify={position}
        >
          {languages.data
            ?.filter((language) =>
              language.language
                .toLowerCase()
                .includes(searchText.trim().toLowerCase())
            )
            .map((language, index) => (
              <WrapItem
                key={index}
                onClick={() => {
                  if (selectedLanguages.includes(language.id)) {
                    setSelectedLanguages(
                      selectedLanguages.filter((i) => i !== language.id)
                    )
                  } else {
                    setSelectedLanguages([...selectedLanguages, language.id])
                  }
                }}
              >
                <Button
                  className={cn(
                    'border bg-gray/60 transition-all duration-300',
                    selectedLanguages.includes(language.id)
                      ? 'bg-green/85 text-white'
                      : ''
                  )}
                  backgroundColor={
                    selectedLanguages.includes(language.id) ? 'lyk-green' : ''
                  }
                  fontWeight={500}
                  color={selectedLanguages.includes(language.id) ? 'white' : ''}
                  rounded={'full'}
                  _hover={{ opacity: [1, 1, 0.6] }}
                  borderColor={
                    selectedLanguages.includes(language.id)
                      ? 'transparent'
                      : 'gray-600'
                  }
                >
                  {language.language}
                </Button>
              </WrapItem>
            ))}
          {languages.isLoading && (
            <>
              <WrapItem>
                <Button
                  className={
                    'w-32 animate-pulse rounded-full border border-gray bg-gray/40'
                  }
                ></Button>
              </WrapItem>
              <WrapItem>
                <Button
                  className={
                    'w-32 animate-pulse rounded-full border border-gray bg-gray/40'
                  }
                ></Button>
              </WrapItem>
              <WrapItem>
                <Button
                  className={
                    'w-32 animate-pulse rounded-full border border-gray bg-gray/40'
                  }
                ></Button>
              </WrapItem>
              <WrapItem>
                <Button
                  className={
                    'w-32 animate-pulse rounded-full border border-gray bg-gray/40'
                  }
                ></Button>
              </WrapItem>
            </>
          )}
        </Wrap>
      </Flex>
    </>
  )
}
