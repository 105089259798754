import { Flex } from '@chakra-ui/react'
import { Button, buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'

export const OnboardingModalFooterGuest = ({
  handleSignup,
  handleNextStep,
}: {
  handleSignup: () => void
  handleNextStep: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  return (
    <>
      <Flex
        justifyContent="space-between"
        flexDir={{ base: 'column-reverse', lg: 'row' }}
        w={'full'}
        px={{
          base: 5,
          sm: 8,
        }}
        py={{ base: 2 }}
        bg={'white'}
      >
        <Button
          type="submit"
          onClick={handleSignup}
          className={cn(
            buttonVariants({
              variant: 'primaryGreen',
            }),
            'w-full self-end px-6 tracking-wide lg:w-32'
          )}
        >
          Signup
        </Button>
        <Button
          type="submit"
          onClick={handleNextStep}
          className={cn(
            buttonVariants({
              variant: 'primaryRed',
            }),
            'my-1 w-full self-end px-6 tracking-wide lg:my-0 lg:w-fit'
          )}
        >
          Apply and continue as guest
        </Button>
      </Flex>
    </>
  )
}
