import { useMutation } from '@tanstack/react-query'
import { User } from '@/src/types/user'
import { useUser } from '@/src/store/user-store'
import { makeApiRequest } from '@/src/lib/api-request'
import { useOnboarding } from '@/src/store/onboarding-store'

interface LanguageInterestsMutationParams {
  languageIds?: string[]
  interestIds?: string[]
}

interface LanguageInterestData {
  languageId: string
  interestId: string
}

export const useLanguageInterestsMutation = ({
  isSignup,
}: {
  isSignup?: boolean
}) => {
  const {
    setLanguageIds,
    setInterestIds,
    languageIds,
    interestIds,
    setIsCompleted,
    isCompleted,
  } = useOnboarding()
  const { setUser } = useUser()
  const { user } = useUser()
  return useMutation({
    mutationKey: ['user', 'language-interests'],
    mutationFn: async (params: LanguageInterestsMutationParams) => {
      const data = {} as LanguageInterestData
      data.languageId = languageIds ?? ''
      data.interestId = interestIds ?? ''
      if (params.languageIds) {
        data.languageId = params.languageIds.join(',')
      }
      if (params.interestIds) {
        data.interestId = params.interestIds.join(',')
      }
      const response = await makeApiRequest({
        method: 'POST',
        isAuthenticated: user ? true : false,
        fallbackError: 'Error while updating language and interests',
        url: isSignup
          ? '/Login_New/addUserLanguageInterest' : user ?
            '/Login_New/updateUserLanguageInterest' : "Login_New/updateUserLanguageInterest_withOutLogin",
        data: data,
        sendCredentials: true,
      })
      if (user) {
        const userData = response.response as User
        setUser(userData)
        setLanguageIds(userData?.languageIds ?? '')
        setInterestIds(userData?.interestIds ?? '')
      }else{
        if (params.languageIds) {
          setLanguageIds(params.languageIds.join(','))
        }
        if (params.interestIds) {
          setInterestIds(params.interestIds.join(','))
        }
      }
      setIsCompleted(true)
    },
    onSuccess: () => {
      console.log('success')
    },
    onError: () => { },
  })
}
