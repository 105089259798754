export interface InterestsProps {
  name: string
  emoji: string
}

export const interests: InterestsProps[] = [
  { name: 'Animation', emoji: '🎨' },
  { name: 'Automobile', emoji: '🚗' },
  { name: 'Bollywood', emoji: '🅱' },
  { name: 'Business', emoji: '💸' },
  { name: 'Comedy', emoji: '😂' },
  { name: 'Cricket', emoji: '🏏' },
  { name: 'Devotional Content', emoji: '🙏' },
  { name: 'Education', emoji: '📚' },
  { name: 'Food', emoji: '🍔' },
  { name: 'Finance & Accounting', emoji: '📈' },
  { name: 'Gaming', emoji: '🎮' },
  { name: 'Informative', emoji: '📰' },
  { name: 'Lifestyle', emoji: '👗' },
  { name: 'Movies & Shows', emoji: '🎥' },
  { name: 'Music', emoji: '🎵' },
  { name: 'Other Entertainment', emoji: '🎬' },
  { name: 'Other Sports', emoji: '⛹' },
  { name: 'Politics', emoji: '🏛️' },
  { name: 'Science & Technology', emoji: '🔬' },
  { name: 'Soccer', emoji: '⚽' },
  { name: 'Sports', emoji: '⛹' },
  { name: 'Travel & Events', emoji: '✈️' },
  { name: 'Crime & Investigation', emoji: '🕵️' },
  { name: 'Erotica', emoji: '❤️‍🔥' },
]

export interface CommunicationLanguagesProps {
  name: string
}

export const communicationLanguages: CommunicationLanguagesProps[] = [
  { name: 'English' },
  { name: 'Bhojpuri' },
  { name: 'Spanish' },
  { name: 'Hindi' },
  { name: 'Arabic' },
  { name: 'Bengali' },
  { name: 'Portuguese' },
  { name: 'Russian' },
  { name: 'Japanese' },
  { name: 'German' },
  { name: 'Mandarin Chinese' },
  { name: 'French' },
  { name: 'Telugu' },
  { name: 'Marathi' },
  { name: 'Turkish' },
  { name: 'Tamil' },
  { name: 'Urdu' },
  { name: 'Gujarati' },
  { name: 'Polish' },
  { name: 'Malayalam' },
  { name: 'Kannada' },
  { name: 'Odia' },
  { name: 'Cantonese' },
]

export interface OnboardingStepProps {
  title: string
  description: string
  subtitle?: string
  subdescription?: string
  thirdStepDescription?: string
}

export const onboardingData: OnboardingStepProps[] = [
  {
    title: 'Welcome to LYKSTAGE!',
    description:
      'Help us make your experience awesome by giving us some information :)',
    subtitle: 'Select your interests.',
    subdescription:
      'This helps us recommend more relevant content. Please choose at least 3.',
  },
  {
    title: 'Welcome to LYKSTAGE!',
    description:
      'Help us make your experience awesome by giving us some information :)',
    subtitle: 'Choose Preferred Language(s) for content.',
    subdescription:
      'We will try to recommend content that is in these languages.',
  },
  {
    title: 'Just give us a few more details :)',
    description: 'This will help us optimize your experience better',
  },
  {
    title: 'Start earning with your watchtime!',
    description:
      'With LYKSTAGE, viewers are rewarded for their watchtime. A unit of watch time can be defined as a block of 30 sec uninterrupted video viewing.',
    // subtitle: '*Rs. 1 = 5 LYK Coins',
    subtitle: 'US$ 1 = 50 LYK Coins',
    subdescription: 'LYK Coins can be converted into real currency.',
    thirdStepDescription: 'Don’t understand how this works? We’ll show you!',
  },
]
