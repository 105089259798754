'use client'

import { Icons } from '../icons'
import { Button, buttonVariants } from '@/components/ui/button'
import { Image } from '@chakra-ui/react'
import Link from 'next/link'
import { Search } from './search'
import { SearchMobile } from './search-mobile'
import classNames from 'classnames'
import { useUser } from '@/src/store/user-store'

export const Navbar = ({
  toggleSidebar,
  isTransparent,
}: {
  toggleSidebar: () => void
  isTransparent?: boolean
}) => {
  const { user } = useUser()
  return (
    <>
      <div className="relative">
        <nav
          className={classNames(
            'z-20 w-full',
            isTransparent ? 'absolute' : 'bg-black'
          )}
        >
          <div
            className={classNames(
              'z-10 flex w-full flex-row items-center xs:justify-between  sm:justify-between md:justify-around',
              isTransparent ? 'bg-transparent' : 'bg-white',
              isTransparent ? 'xs:px-4 xs:py-3 sm:px-6 sm:py-4' : 'xs:px-4'
            )}
          >
            <div className="logo flex xs:gap-x-2 md:gap-x-4">
              <div className="flex items-center justify-center gap-2">
                <Icons.HamburgerMenu
                  className={classNames(
                    'mt-[7px] h-6 w-6 cursor-pointer',
                    isTransparent ? 'text-white' : 'text-black'
                  )}
                  onClick={toggleSidebar}
                />
                <div className="relative">
                  <Link href="/home">
                    <Image
                      src={`/lykstage-logo.svg`}
                      alt="logo"
                      w={['150px', '150px', '160px', '170px']}
                      h="auto"
                      minW="110px"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="hidden w-full flex-1 items-center justify-center space-x-5 md:flex">
              <Search isTransparent={isTransparent} />
              {/* <Icons.Microphone className="w-[2.8rem] cursor-pointer rounded-full bg-white p-2 opacity-75 xs:hidden sm:hidden md:block " /> */}
            </div>
            <div className="flex items-center justify-center xs:gap-1 sm:gap-2">
              <Link
                href="/how-earnings-work"
                className={classNames(
                  'pl-2  xs:hidden sm:hidden md:block md:pl-0',
                  isTransparent ? 'text-white' : 'text-black'
                )}
              >
                Rewards
              </Link>
              <div className="bg-primary flex cursor-pointer items-center justify-center gap-1 rounded-lg py-2 pl-2 xs:text-xs md:text-sm xxl:py-3 xxl:pl-4">
                <Link
                  href={user ? '/home' : '/auth/signup'}
                  className={classNames(
                    'cursor-pointer xs:hidden sm:hidden md:block',
                    isTransparent ? 'text-white' : 'text-black'
                  )}
                >
                  <Icons.CamPlus className="md:w-[2rem] lg:w-[3rem]" />
                </Link>
                <SearchMobile />
                <Button
                  asChild
                  style={{ borderRadius: '4px' }}
                  className="ml-2"
                >
                  <Link
                    href={user ? '/home' : '/auth/signup'}
                    className={buttonVariants({
                      variant: 'primaryGreen',
                    })}
                  >
                    Get Started
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
