import { Wrap, WrapItem, Box, Button } from '@chakra-ui/react'
// import { Button } from '../ui/button'
import { interests as interestEmojis } from '@/data/auth/onboarding'
// import { useState } from 'react'
import { cn } from '@/lib/utils'
import { useInterestQuery } from '@/src/queries/auth/interests-query'

export const Interests = ({
  selectedInterests,
  setSelectedInterests,
  position,
  spacing,
}: {
  selectedInterests: string[]
  setSelectedInterests: (selectedInterests: string[]) => void
  position: string
  spacing: string
}) => {
  const interests = useInterestQuery()

  const sortedInterests = interests.data?.sort((a, b) =>
    a.interest.localeCompare(b.interest)
  )

  return (
    <Wrap
      spacing={{
        base: 2,
        sm: spacing,
      }}
      justify={position}
    >
      {sortedInterests?.map((interest, index) => (
        <WrapItem
          key={index}
          onClick={() => {
            if (selectedInterests.includes(interest.interestId)) {
              setSelectedInterests(
                selectedInterests.filter((i) => i !== interest.interestId)
              )
            } else {
              setSelectedInterests([...selectedInterests, interest.interestId])
            }
          }}
        >
          <Button
            className={cn(
              'border bg-gray/60 transition-all duration-300',
              selectedInterests.includes(interest.interestId)
                ? 'bg-green/85 text-white'
                : ''
            )}
            backgroundColor={
              selectedInterests.includes(interest.interestId) ? 'lyk-green' : ''
            }
            color={
              selectedInterests.includes(interest.interestId) ? 'white' : ''
            }
            fontWeight={500}
            rounded={'full'}
            _hover={{ opacity: [1, 1, 0.6] }}
            borderColor={
              selectedInterests.includes(interest.interestId)
                ? 'transparent'
                : 'gray-600'
            }
          >
            <Box mr={[1]}>
              {interestEmojis.find((i) => i.name === interest.interest)
                ?.emoji || '🌍'}
            </Box>
            &nbsp;{interest.interest}
          </Button>
        </WrapItem>
      ))}
      {interests.isLoading && (
        <>
          <WrapItem>
            <Button
              className={
                'w-32 animate-pulse rounded-full border border-gray bg-gray/40'
              }
            ></Button>
          </WrapItem>
          <WrapItem>
            <Button
              className={
                'w-32 animate-pulse rounded-full border border-gray bg-gray/40'
              }
            ></Button>
          </WrapItem>
          <WrapItem>
            <Button
              className={
                'w-32 animate-pulse rounded-full border border-gray bg-gray/40'
              }
            ></Button>
          </WrapItem>
          <WrapItem>
            <Button
              className={
                'w-32 animate-pulse rounded-full border border-gray bg-gray/40'
              }
            ></Button>
          </WrapItem>
        </>
      )}
    </Wrap>
  )
}
