import { createJSONStorage, persist } from 'zustand/middleware'
import { create } from 'zustand'

export interface SearchSlice {
   history : string[],
   setHistory : (val : string) => void
}

export const useSearch = create(
  persist<SearchSlice>(
    (set,get) => ({
        history : [],
        setHistory:(term:string)=>{
          if(get().history.length<3){
            set({history:[...get().history,term]})
          }
          else{
            set({history : [...get().history.slice(1), term]})
          }
        }       
    }),
    {
      name: 'search-storage-lykstage',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
