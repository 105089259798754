import { Flex } from '@chakra-ui/react'
import { Button, buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'

export const OnboardingModalFooterUser = ({
  handleCancel,
  handleChanges,
}: {
  handleCancel: () => void
  handleChanges: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  return (
    <>
      <Flex
        alignItems={'center'}
        flexDir={{ base: 'column', lg: 'row-reverse' }}
        w={'full'}
        px={{
          base: 5,
          sm: 8,
        }}
        py={{ base: 2 }}
        bg={'white'}
      >
        <Button
          type="submit"
          onClick={handleChanges}
          className={cn(
            buttonVariants({
              variant: 'primaryGreen',
            }),
            'w-full px-6 tracking-wide lg:w-fit'
          )}
        >
          Apply
        </Button>
        <Button
          type="submit"
          onClick={handleCancel}
          className={cn(
            buttonVariants({
              variant: 'destructive',
            }),
            'mx-2 mt-2 w-full px-6 tracking-wide lg:m-0 lg:mx-2 lg:w-32 '
          )}
        >
          Cancel
        </Button>
      </Flex>
    </>
  )
}
