'use client'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useState } from 'react'
import { OnboardingModalHeader } from './onboarding/onboarding-modal-header'
import { OnboardingModalFooterGuest } from './onboarding/onboarding-modal-footer-guest'
import { Languages } from '../auth-page/languages-onboarding'
import { onboardingData } from '@/data/auth/onboarding'
import { useUser } from '@/src/store/user-store'
import { useRouter } from 'next/navigation'
import { OnboardingModalFooterUser } from './onboarding/onboarding-modal-footer-user'
import { useOnboarding } from '@/src/store/onboarding-store'
import { useLanguageInterestsMutation } from '@/src/mutations/auth/interest-language-mutation'
import { toast } from 'sonner'

const LanguageModalContent = ({
  closeHandler,
}: {
  closeHandler: () => void
}) => {
  const router = useRouter()
  const { user } = useUser()
  const { setLanguageIds, languageIds } = useOnboarding()
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    languageIds?.length ? languageIds.split(',') : []
  )
  const languageInterestsMutation = useLanguageInterestsMutation({})
  const position = useBreakpointValue({ base: 'flex-start', lg: 'center' })

  const handleSignup = () => {
    router.push('/auth/signup')
  }

  const handleNextStep = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    if (selectedLanguages.length < 3) {
      toast.error('Please select atleast three languages')
    } else {
      languageInterestsMutation.mutate(
        {
          languageIds: selectedLanguages,
        },
        {
          onSuccess: () => {
            toast.success('Your languages have been saved!')
          },
          onError: (error) => {
            toast.error(error.message)
          },
        }
      )
      if (!user) {
        setLanguageIds(selectedLanguages.join(','))
      }

      closeHandler()
    }
  }

  return (
    <Box className="mx-auto flex h-full w-full flex-col overflow-hidden">
      <VStack
        mx={[0]}
        className="mx-auto flex flex-1 flex-col items-start overflow-hidden px-5 md:px-8"
      >
        <div className="px-2 pb-2 pr-4">
          <OnboardingModalHeader
            subtitle={onboardingData[1].subtitle}
            subdescription={undefined}
          />
        </div>
        <ModalCloseButton className="closeButton" />

        <div className="flex-1 overflow-y-scroll border-b pb-5 scrollbar-none sm:pb-0">
          <Languages
            selectedLanguages={selectedLanguages}
            setSelectedLanguages={setSelectedLanguages}
            position={position ?? 'center'}
          />
        </div>
      </VStack>

      {user ? (
        <OnboardingModalFooterUser
          handleCancel={closeHandler}
          handleChanges={handleNextStep}
        />
      ) : (
        <OnboardingModalFooterGuest
          handleSignup={handleSignup}
          handleNextStep={handleNextStep}
        />
      )}
    </Box>
  )
}

export const LanguageModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'full', sm: '' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: '100dvw', sm: '70dvw', md: '50vw', '3xl': '50dvw' }}
          maxW={{ base: '100%', sm: '800px' }}
          h={['100dvh', '100dvh', '80dvh', '72dvh', '420px']}
        >
          <ModalBody h="100%" px={0}>
            <LanguageModalContent closeHandler={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
