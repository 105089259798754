import { Icons } from '../icons'
import { cn } from '@/lib/utils'
import Link from 'next/link'
import { Image, useDisclosure } from '@chakra-ui/react'
import { InterestModal } from '../ui/interest-modal'
import { LanguageModal } from '../ui/language-modal'
import { useLanguagesQuery } from '@/src/queries/auth/languages-query'
import { useInterestQuery } from '@/src/queries/auth/interests-query'
import { useUser } from '@/src/store/user-store'
import { useOnboarding } from '@/src/store/onboarding-store'
import { useEffect } from 'react'
import { usePathname } from 'next/navigation'

export const Sidebar = ({
  closeSidebar,
  isSideBarOpen,
  closeSidebarOnly,
}: {
  closeSidebar: () => void
  isSideBarOpen: boolean
  closeSidebarOnly: () => void
}) => {
  const pathname = usePathname()
  const {
    isOpen: isInterestOpen,
    onOpen: onInterestOpen,
    onClose: onInterestClose,
  } = useDisclosure()
  const allLanguages = useLanguagesQuery().data
  const allInterests = useInterestQuery().data
  const { user } = useUser()
  const { languageIds, interestIds } = useOnboarding()
  const languageIdsList = user
    ? user?.languageIds?.split(',')
    : languageIds
      ? languageIds.split(',')
      : []
  const interestIdsList = user
    ? user?.interestIds?.split(',')
    : interestIds
      ? interestIds.split(',')
      : []

  const {
    isOpen: isLanguageOpen,
    onOpen: onLanguageOpen,
    onClose: onLanguageClose,
  } = useDisclosure()

  const languages = allLanguages?.flatMap((language) =>
    languageIdsList?.includes(language.id) ? [language.language] : []
  )
  const interests = allInterests?.flatMap((interest) =>
    interestIdsList?.includes(interest.interestId) ? [interest.interest] : []
  )
  useEffect(() => {
    if (pathname) {
      closeSidebarOnly()
    }
  }, [pathname])
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (window.location.origin + pathname === event.currentTarget.href) {
      closeSidebarOnly()
    }
  }

  return (
    <>
      <InterestModal isOpen={isInterestOpen} onClose={onInterestClose} />
      <LanguageModal isOpen={isLanguageOpen} onClose={onLanguageClose} />

      <div
        className={cn(
          'ease fixed inset-0 z-[998] bg-[#000] transition-all duration-500',
          isSideBarOpen ? 'visible opacity-50' : 'invisible opacity-0'
        )}
        onClick={closeSidebar}
      />

      <div
        className="ease fixed bottom-0 left-0 top-0 z-[999] flex w-[313px] flex-col bg-white transition-all duration-500"
        style={{
          left: isSideBarOpen ? '0' : '-313px',
        }}
      >
        <div className="flex flex-1 flex-col gap-6 overflow-hidden px-5 pt-5 ">
          <div className="flex items-center gap-4">
            <Icons.HamburgerMenu
              className="mt-[5px] h-6 w-6 cursor-pointer text-black"
              onClick={closeSidebar}
            />
            <span>
              <div className="relative font-semibold">
                <Link href="/home" onClick={handleLinkClick}>
                  <Image
                    src={`/lykstage-logo.svg`}
                    alt="logo"
                    w={['150px', '150px', '160px', '170px']}
                    h="auto"
                    minW="110px"
                  />
                </Link>
              </div>
            </span>
          </div>
          <div className="flex-1 overflow-y-scroll py-2 pr-2 scrollbar-none">
            <ul className="text-[16px]">
              <a href="/home">
                <li className="flex cursor-pointer items-center gap-2 rounded-lg  px-5 py-3 hover:bg-[#ECECEC]">
                  <span className="icons">
                    <Icons.Home className="h-[20px] w-[20px] text-black" />
                  </span>
                  <span className="text">Home</span>
                </li>
              </a>
              <a href="/earnings">
                <li className="my-1 flex cursor-pointer items-center gap-2 rounded-lg px-5 py-3 hover:bg-[#ECECEC]">
                  <span className="icons">
                    <Icons.Wallet className="h-[20px] w-[20px] text-black" />
                  </span>
                  <span className="text">Earnings</span>
                </li>
              </a>
              <a href="/subscriptions">
                <li className="my-1 flex cursor-pointer items-center gap-2 rounded-lg px-5 py-3 hover:bg-[#ECECEC]">
                  <span className="icons">
                    <Icons.UserCheck className="h-[20px] w-[20px] text-black" />
                  </span>
                  <span className="text">Subscription</span>
                </li>
              </a>
              <a href="/watchlist">
                <li className="my-1 flex cursor-pointer items-center gap-2 rounded-lg px-5 py-3 hover:bg-[#ECECEC]">
                  <span className="icons">
                    <Icons.Watchlist className="h-[20px] w-[20px] text-black" />
                  </span>
                  <span className="text">Watchlist</span>
                </li>
              </a>
              {/* <a href="/rentals">
                <li className="my-1 flex cursor-pointer items-center gap-2 rounded-lg px-5 py-3  hover:bg-[#ECECEC]">
                  <span className="icons">
                    <Icons.Rentals className="h-[20px] w-[20px] text-black" />
                  </span>
                  <span className="text">Rentals &amp; Premium</span>
                </li>
              </a> */}
            </ul>
            <hr className="my-4 border-[#E0DFDF]" />
            <p className="pb-3 text-[16px]">
              Sign in to like videos,
              <br /> subscribe, and earn!
            </p>
            <Link href="/auth/login" onClick={handleLinkClick}>
              <button className="w-full rounded-lg bg-[#24BFA3] py-2 text-[16px] text-white duration-300 hover:bg-[#449887]">
                Sign in
              </button>
            </Link>
            <hr className="my-4 border-[#E0DFDF]" />
            <div className="text-[16px]">
              <p className="flex items-center gap-3" onClick={onLanguageOpen}>
                Languages
                <Icons.Write className="h-[20px] w-[20px] text-black" />
              </p>
              <p className="pt-2 text-[14px] text-black/80">
                {languages?.length === 0 && 'No language preferences'}
                {languages?.slice(0, 3).join(', ')} <br />{' '}
                {languages?.length && languages?.length > 3
                  ? '+' + (languages?.length - 3) + ' More'
                  : ''}
              </p>
            </div>
            <hr className="my-4 border-[#E0DFDF]" />
            <div className="text-[16px]">
              <p className="flex items-center gap-3" onClick={onInterestOpen}>
                Interests
                <Icons.Write className="h-[20px] w-[20px] text-black" />
              </p>
              <p className="pt-2 text-[14px] text-black/80">
                {interests?.length === 0 && 'No interest preferences'}
                {interests?.slice(0, 3).join(', ')} <br />{' '}
                {interests?.length && interests?.length > 3
                  ? '+' + (interests?.length - 3) + ' More'
                  : ''}
              </p>
            </div>
            <hr className="my-4 border-[#E0DFDF]" />
            <Link
              onClick={handleLinkClick}
              href={'/how-earnings-work'}
              className="hover:opacity-0.6 text-green hover:underline"
            >
              How earnings work &rarr;
            </Link>
            <p className="my-2 font-medium text-[#3E3E3EB2]">
              Got more questions?{' '}
              <Link
                onClick={handleLinkClick}
                href={'/faqs'}
                className="hover:opacity-0.6 text-green hover:underline"
              >
                View FAQs
              </Link>
            </p>
            <p className="mt-3 pb-20 text-[15px]">© 2024 Lykstage</p>
          </div>
        </div>
      </div>
    </>
  )
}
