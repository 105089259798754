import { getErrorMessageErrorResponse } from '@/src/lib/utils'
import { ErrorResponse } from '@/src/types/api-error'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

interface LocationDto {
  country: string
  city: string
  ip: string
  countryCode: string
}


export const useLocationQuery = () => {
  return useQuery({
    queryKey: ['country', 'city'],
    queryFn: async () => {
      try {
        const response = await axios.get('https://backend.lykstage.com/maxmind/ipdata.php') 
        const data = response.data.response;
        return data as LocationDto
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          throw new Error(
            getErrorMessageErrorResponse(
              e.response?.data as ErrorResponse,
              'Failed to fetch location'
            )
          )
        }
        throw new Error('Failed to fetch location')
      }
    },
    refetchOnMount : false,
    staleTime: Infinity,
  })
}
