'use client'

import { useState, useEffect, useCallback } from 'react'
import { Icons } from '../icons'
import { Input } from '../ui/input'
import { Icon, Text } from '@chakra-ui/react'
import { useRouter, useSearchParams } from 'next/navigation'
import { useDebounce } from '@uidotdev/usehooks'
import { useUser } from '@/src/store/user-store'
import { useSearch } from '@/src/store/search-store'
import {
  useSearchOnTypeLandingQuery,
  useSearchLandingQuery,
} from '@/src/queries/landing/search'
import { RxCross1 } from 'react-icons/rx'
import classNames from 'classnames'

export const Search = ({ isTransparent }: { isTransparent?: boolean }) => {
  const router = useRouter()
  const { user } = useUser()
  const { history } = useSearch()
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const sp = useSearchParams()
  const debouncedQuery = useDebounce(searchVal, 200)

  const { data: searchText } = useSearchOnTypeLandingQuery(debouncedQuery)

  const { data: searchRecommendations } = useSearchLandingQuery()

  useEffect(() => {
    setSearchVal(
      sp
        ?.get('q')
        ?.replace(/^["'](.+(?=["']$))["']$/, '$1')
        .replaceAll('"', '') || ''
    )
  }, [sp])

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(sp?.toString())
      params.set(name, value)
      return params.toString()
    },
    [sp]
  )

  const handleSearchSubmit = () => {
    if (searchVal.length == 0) {
      return
    } else {
      setIsPopupVisible(false)
      if (!user) {
      }
      router.push('/search/?' + createQueryString('q', searchVal))
    }
  }

  const handleSuggestionSubmit = (suggestion: string) => {
    setIsPopupVisible(false)
    setSearchVal(suggestion)
    router.push('/search/?' + createQueryString('q', suggestion))
  }

  const [searchData, setSearchData] = useState<string[]>([])
  const [trendingData, setTrendingData] = useState<string[]>([])

  const showPopup = () => {
    setIsPopupVisible(true)
  }

  const hidePopup = () => {
    setIsPopupVisible(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value)
  }

  useEffect(() => {
    if (searchVal) {
      setSearchData(searchText?.map((st) => st.description) || [])
      setTrendingData([])
    } else {
      if (!user) {
        const newData =
          searchRecommendations
            ?.filter((fil) => fil.sreachType === 'history')
            ?.map((st) => st.description) || []
        setSearchData(newData)
        setTrendingData(
          searchRecommendations
            ?.filter((fil) => fil.sreachType === 'popular')
            ?.map((st) => st.description) || []
        )
      } else {
        setSearchData(
          searchRecommendations
            ?.filter((fil) => fil.sreachType === 'history')
            ?.map((st) => st.description) || []
        )
        setTrendingData(
          searchRecommendations
            ?.filter((fil) => fil.sreachType === 'popular')
            ?.map((st) => st.description) || []
        )
      }
    }
  }, [searchVal, searchText, searchRecommendations])

  return (
    <div className="relative w-[70%] max-w-[30rem]">
      <div
        className={classNames(
          'flex flex-1 items-center overflow-hidden rounded-full  border-[#d3d3d3] bg-[#FFFFFF84] px-4 opacity-75 focus-within:border-[#d3d3d3] active:border-[#d3d3d3]',
          isTransparent ? 'border-2 border-white' : 'border-2'
        )}
      >
        <Input
          type="text"
          className="focus-visible:out z-0 h-10 w-full flex-1 border-0 bg-[#ffffff08] pl-0 pr-2 text-white placeholder:text-[14px] placeholder:font-normal placeholder:text-white focus:border-0 focus:outline-none focus:ring-0 focus-visible:shadow-none focus-visible:outline-none focus-visible:ring-0 active:border-[#d3d3d3]"
          placeholder="Search"
          onFocus={showPopup}
          onBlur={hidePopup}
          defaultValue={sp?.get('q') || ''}
          value={searchVal}
          autoComplete="false"
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key == 'Enter') {
              handleSearchSubmit()
            }
          }}
        />
        {searchVal.length > 0 ? (
          <Icon
            as={RxCross1}
            color="lyk-red"
            className="h-5 w-5 cursor-pointer lg:h-5 lg:w-5"
            onClick={() => setSearchVal('')}
          />
        ) : (
          <Icons.Search className="h-5 w-5 cursor-pointer text-white lg:h-6 lg:w-6" />
        )}
      </div>
      {isPopupVisible &&
        ((searchData && searchData.length > 0) ||
          (trendingData && trendingData.length > 0)) && (
          <div className="absolute top-[3.5rem] z-30 h-auto  w-full rounded-2xl border-[1px] bg-white px-1 py-3 text-start text-[#7A7A7A]">
            <ul className="px-1 text-[14px]">
              {searchData &&
                searchData.length > 0 &&
                searchData.map((item, index) => (
                  <li
                    key={index}
                    onMouseDown={() => {
                      handleSuggestionSubmit(item)
                    }}
                    className="flex items-center gap-3 rounded-md p-2 py-1 hover:bg-gray"
                  >
                    <div className="pr-2">
                      <Icons.Search className="h-auto w-[20px] text-black/80" />
                    </div>
                    <SearchCompletion
                      text={item.toLowerCase()}
                      query={searchVal.trim().toLowerCase()}
                    />
                  </li>
                ))}
              {trendingData &&
                trendingData.length > 0 &&
                trendingData.map((item, index) => (
                  <li
                    key={index}
                    onMouseDown={() => {
                      handleSuggestionSubmit(item)
                    }}
                    className="flex items-center gap-3  p-2 px-3 py-1 hover:bg-gray"
                  >
                    <div className="cursor-pointer pr-2">
                      <Icons.trendingUp className="h-auto w-[20px] text-green" />
                    </div>
                    <Text>
                      <span className="font-semibold text-green">
                        {item.toLowerCase()}
                      </span>
                    </Text>
                  </li>
                ))}
            </ul>
          </div>
        )}
    </div>
  )
}

export const SearchCompletion = ({
  text,
  query,
}: {
  text: string
  query: string
}) => {
  const index = text.indexOf(query)
  const before = index < 0 ? text : text.slice(0, index)
  const after = text.slice(index + query.length)

  return (
    <Text>
      <span className="font-semibold text-black">{before}</span>
      <span className="font-medium text-[#7A7A7A]">
        {index >= 0 ? query : ''}
      </span>
      <span className="font-semibold text-black">
        {index >= 0 ? after : ''}
      </span>
    </Text>
  )
}
