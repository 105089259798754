'use client'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useState } from 'react'
import { OnboardingModalHeader } from './onboarding/onboarding-modal-header'
import { OnboardingModalFooterGuest } from './onboarding/onboarding-modal-footer-guest'
import { onboardingData } from '@/data/auth/onboarding'
import { useUser } from '@/src/store/user-store'
import { useRouter } from 'next/navigation'
import { OnboardingModalFooterUser } from './onboarding/onboarding-modal-footer-user'
import { useOnboarding } from '@/src/store/onboarding-store'
import { useLanguageInterestsMutation } from '@/src/mutations/auth/interest-language-mutation'
import { toast } from 'sonner'
import { Interests } from '../auth-page/interests-onboarding'

const InterestModalContent = ({
  closeHandler,
}: {
  closeHandler: () => void
}) => {
  const router = useRouter()
  const { user } = useUser()
  const { setInterestIds, interestIds } = useOnboarding()
  const [selectedInterests, setSelectedInterests] = useState<string[]>(
    interestIds?.length ? interestIds.split(',') : []
  )
  const languageInterestsMutation = useLanguageInterestsMutation({})
  const position = useBreakpointValue({ base: 'flex-start', lg: 'center' })
  const spacing = useBreakpointValue({ base: '5', sm: '3' })
  const handleSignup = () => {
    router.push('/auth/signup')
  }

  const handleNextStep = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    if (selectedInterests.length < 3) {
      toast.error('Please select at least three interests')
    } else {
      languageInterestsMutation.mutate(
        {
          interestIds: selectedInterests,
        },
        {
          onSuccess: () => {
            toast.success('Your interests have been saved!')
          },
          onError: (error) => {
            toast.error(error.message)
          },
        }
      )
      if (!user) {
        setInterestIds(selectedInterests.join(','))
      }

      closeHandler()
    }
  }

  return (
    <Box className="mx-auto flex h-full w-full flex-col overflow-hidden">
      <VStack
        mx={[0]}
        className="mx-auto flex flex-1 flex-col overflow-hidden px-5 md:px-8"
      >
        <ModalCloseButton className="closeButton" />
        <OnboardingModalHeader
          subtitle={onboardingData[0].subtitle}
          subdescription={onboardingData[0].subdescription}
        />
        <Box className="flex-1 overflow-y-scroll border-b pt-2 scrollbar-none sm:pb-5">
          <Interests
            selectedInterests={selectedInterests}
            setSelectedInterests={setSelectedInterests}
            position={position ?? 'center'}
            spacing={spacing ?? '5'}
          />
        </Box>
      </VStack>
      <Box>
        {user ? (
          <OnboardingModalFooterUser
            handleCancel={closeHandler}
            handleChanges={handleNextStep}
          />
        ) : (
          <OnboardingModalFooterGuest
            handleSignup={handleSignup}
            handleNextStep={handleNextStep}
          />
        )}
      </Box>
    </Box>
  )
}

export const InterestModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'full', sm: '' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: '100dvw', sm: '70dvw', md: '50dvw', '3xl': '50dvw' }}
          maxW={{ base: '100%', sm: '800px' }}
          h={['100dvh', '100dvh', '80dvh', '72dvh', '420px']}
        >
          <ModalBody h="100%" px={0}>
            <InterestModalContent closeHandler={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
